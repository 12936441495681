import React from 'react'

import Layout from '../components/layout'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Container from '../components/container'
import Seo from '../components/seo'

import ActivityCard from '../components/activity-card'

import { mapEdgesToNodes } from '../utilities/helpers'
import { graphql } from 'gatsby'

import clsx from 'clsx'

const ActivitiesPage = props => {
  const { data } = props
  const activities = data && data.activities && mapEdgesToNodes(data.activities)

  return (
    <Layout>
      <Seo title="Aktiviteter" />
      <Container className={clsx(
        `[--vkd-underline-color:var(--vkd-black)]`,
        `mb-[32px] md:mb-[100px]`
      )} stretch={true}>
        <Header />
      </Container>
      <Container>
        <h1 className={`text-h2 md:text-h2-md font-bold mb-[24px]`}>
          Aktiviteter
        </h1>
        <section className={`activity-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px] md:gap-[32px] mb-[60px] md:mb-[100px]`}>
          {activities && activities.map((activity, index) => (
            <ActivityCard key={index} {...activity} />
          ))}
        </section>
      </Container>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    activities: allSanityActivity(
      sort: {
        fields: orderRank
      }
      filter: {
        visibility: {
          regex: "/^(activities|both)$/"
        }
        slug: {
          current: {
            ne: null
          }
        }
        category: {
          slug: {
            current: {
              ne: null
            }
          }
        },
        location: {
          lat: {
            ne: null
          },
          lng: {
            ne: null
          }
        }
      }
    ) {
      edges {
        node {
          ...ActivityFields
        }
      }
    }
  }
`

export default ActivitiesPage
